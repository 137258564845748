import './About.scss'
import Resume from "./Resume/Resume.pdf";

function About() {
    return (
        <div className="container-about" id='about'>
            <div className='wrapper-about'>
                <div className='main-about'>
                    <label className='fw-bold'>ABOUT ME</label>
                    <span className='dash'>
                      
                    </span>
                </div>
                <div className='about-content'>
                    <div className='about-details grid-gap'>
                        <h3 className='fw-bold'>Get to know me!</h3>
                        <div className='description'>
                            <p>I'm a Full-Stack software Developer, pursuing Master's in Computer
                                Science from DePaul University, Chicago. I've over 4.5 years of
                                experience as a Software Developer at Tata Consultancy Services.
                            </p>
                            <p>During my tenure, I was deputed to Japan to work with the clients.
                                My roles and responsibilities there were as a Developer as well as
                                Onsite Coordinator
                            </p>
                        </div>
                        <span className='resume-span'>
                            <a href={Resume} download="Resume" className="button-resume a">
                                Resume
                            </a>
                        </span>
                    </div>
                    <div className='about-skills grid-gap'>
                        <h3 className='fw-bold'>SKILLS</h3>
                        <div className='skills'>
                            <div className='sub-skill'>HTML</div>
                            <div className='sub-skill'>SCSS</div>
                            <div className='sub-skill'>JavaScript</div>
                            <div className='sub-skill'>C#</div>
                            <div className='sub-skill'>Java</div>
                            <div className='sub-skill'>Asp.Net Core</div>
                            <div className='sub-skill'>React</div>
                            <div className='sub-skill'>MVC</div>
                            <div className='sub-skill'>WPF</div>
                            <div className='sub-skill'>Python</div>
                            <div className='sub-skill'>PL/SQL</div>
                            <div className='sub-skill'>MS-SQL</div>
                            <div className='sub-skill'>Web Application</div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
  }
  
  export default About;