import './Home.scss'
import linkedin from "./Images/linkedin.svg";
import github from "./Images/git.svg";
import insta from "./Images/insta.svg";
import { Link} from 'react-scroll';


function Home() {
   

    return (
        
        <div className="container-home" id='home'>
           
            <div className="wrapper">
                <div className='main-home'>
                   
                    <div className="title">
                        <label className='fw-bolder'>Hey, I'm <span>Shweta Kohinkar</span></label>
                        <h3>Crafting seamless digital experiences from frontend to backend, one line of code at a time.</h3>
                        <div className='about-me'>
                            <Link activeClass="active" className='' to="about" spy={true} smooth={true} duration={500}>About Me</Link>

                               

                        </div>
                    </div>
                    <div className="flyer-wrapper">
                        <a href="https://www.linkedin.com/in/shweta-kohinkar/" target="_blank">
                            <img src={linkedin} className="logo-size" />
                        </a>
                        <a href="https://github.com/ShwetaKohinkar" target="_blank">
                            <img src={github} className="logo-size" />
                        </a>
                        <a href="https://www.instagram.com/shweta_kohinkar/" target="_blank">
                            <img src={insta} className="logo-size" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
  }
  
  export default Home;