import "./PhoneMenu.scss";
import cross from "./Images/cross.png";

import { Link} from 'react-scroll';


function PhoneMenu() {

    function closeNav(){
        const element = document.getElementById("phone-nav");
        element?.classList.toggle("open-Nav");
    }

  return (
    <div id="phone-nav" className="background-container">
    <div  className="phone-menu-container" >
      <div className="phone-menu-wrapper">
        <img src={cross}  onClick={closeNav}/>
        <div className="menu-options">
          <Link activeClass="active" onClick={closeNav} className='' to="home" spy={true} smooth={true} duration={500}>HOME</Link>
          <Link activeClass="active" onClick={closeNav} className='' to="about" spy={true} smooth={true} duration={500}>ABOUT</Link>
          <Link activeClass="active" onClick={closeNav} className='' to="work" spy={true} smooth={true} duration={500}>WORK</Link>
          <Link activeClass="active" onClick={closeNav} className='' to="contact" spy={true} smooth={true} duration={500}>CONTACT</Link>
        </div>
      </div>
    </div>
    </div>
  );
}

export default PhoneMenu;
