import "./Header.scss";
import { Link} from 'react-scroll';
import menu from "./Images/menu.png";
import logo from "./Images/logo.svg";


function Header() {

  function openNav(){
    const element = document.getElementById("phone-nav");
    element?.classList.toggle("open-Nav");

  }
  return (
    <div className="header-container">
      <div className="head-wrapper">
        <div className="name">
          <Link activeClass="current-active-page"  to="home" spy={true} smooth={true} duration={500} className="logo">
            <img className="logo" src={logo} alt="logo"/>
          </Link>
          <label>Shweta Kohinkar</label>
        </div>
        <div className="header">
          <Link activeClass="current-active-page"  to="home" spy={true} smooth={true} duration={500}>HOME</Link>
          <Link activeClass="current-active-page"  to="about" spy={true} smooth={true} duration={500}>ABOUT</Link>
          <Link activeClass="current-active-page"  to="work" spy={true} smooth={true} duration={500}>WORK</Link>
          <Link activeClass="current-active-page"  to="contact" spy={true} smooth={true} duration={500}>CONTACT</Link>
        </div>
        <img src={menu} alt="menu Option" onClick={openNav}/>
      </div>
    </div>
  );
}

export default Header;
