import "./Footer.scss";
import linkedin from "./Images/linkedI2.png";
import github from "./Images/github.png";
import insta from "./Images/instagram.png";
import twitter from "./Images/twitter.png";
import phone from "./Images/phone.png";
import email from "./Images/email.png";
import location from "./Images/location.png";

function Footer() {
  return (
    <div className="footer-container" id="footer">
      <div className="wrapper-footer">
        <div className="footer-grid">
          <div className="t-content">
          <div className="left">
            <label className="text-white fw-bold h4">SHWETA KOHINKAR</label>
            <div className="contact-details">
              <div className="email">
                <img src={email} />
                <label>shwetakohinkar30@gmail.com</label>
              </div>
              <div className="phone">
                <img src={phone} />
                <label>+1 (872)-258-7475</label>
              </div>
              <div className="location">
                <img src={location} />
                <label>Chicago, IL, USA</label>
              </div>
            </div>
          </div>
          <div className="right">
            <label className="text-white fw-bold h4">SOCIAL</label>
            <div className="flyer-wrapper">
              <a
                href="https://www.linkedin.com/in/shweta-kohinkar/"
                target="_blank" >
                <img src={linkedin} className="logo-size" />
              </a>
              <a
                href="https://github.com/ShwetaKohinkar/"
                target="_blank">
                <img src={github} className="logo-size" />
              </a>
              <a href="https://www.instagram.com/shweta_kohinkar/" target="_blank">
                <img src={insta} className="logo-size" />
              </a>
              <a href="https://twitter.com/shwetakohinkar" target="_blank">
                <img src={twitter} className="logo-size" />
              </a>
            </div>
          </div>
          </div>
          <div className="b-content">
             <hr class="custom-hr"/>  
             <label>© Copyright 2023. Made by Shweta Kohinkar</label> 
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
