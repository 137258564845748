import './App.scss';
import Home from './Home';
import Header from './Header';
import About from './About';
import Work from './Work';
import Contact from './Contact';
import Footer from './Footer';
import PhoneMenu from './PhoneMenu';


function App() {
  return (
    // <BrowserRouter>
    //   <Routes>
    //     <Route path="/" element={<Home />} />
    //     <Route path="/about" element={<About />} />
    //   </Routes>
    // </BrowserRouter>
    // <div>
    //   <Element name="Home"/>
    //   <Element name="About"/>
    //   <Element name="Home"/>
    // </div>
    <div className='parent'>
       <Header/>
       <PhoneMenu/>
      <Home/>
      <About/>
      <Work/>
      <Contact/>
      <Footer/>
    </div>
  );
}

export default App;
