import { useState } from "react";
import "./Contact.scss";
import emailjs from '@emailjs/browser';
import { useEffect } from "react";

function Contact() {

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [sent, setSent] = useState(false);

  const sendEmail = (e) => {
     e.preventDefault();
    console.log(getTemplate(e.target));
    emailjs
      .send(
        "service_z40s97c",
        "template_i5m4ucf",
        getTemplate(e.target),
        "3v-EoaIEbjhWjphTY"
      )
      .then(
        (result) => {
         setSent(true);
         resetForm();
        },
        (error) => {
          console.log(error.text);
        }
      );
    setSent(true);
    resetForm(e);


  };

  const getTemplate =()=>{
    const templateParams= {
      "name" : name,
      "email": email,
      "message": message
    };
    return templateParams;

  }

  const resetForm =(e)=>{
   e.target.reset();
  }

  useEffect(()=>{}, [name]);

  return (
    <div className="container-contact" id="contact">
      <div className="wrapper-contact">
        <div className="main-contact">
          <label className="fw-bold">CONTACT</label>
          <span className="dash-contact"></span>
        </div>
        <div className="contact-form-container">
          <form className="contact-form" onSubmit={sendEmail} >
            <label className="fw-bold h3">Get In Touch!</label>
            <div className="row row-details">
              <div className="row">
                <input
                  type="text"
                  id="username"
                  name="username"
                  onChange={e=>setName(e.target.value)}
                  placeholder="Enter Your Name"
                  required
                />
              </div>
              <div className="row">
                <input
                  type="text"
                  id="email"
                  name="email"
                  onChange={e=>setEmail(e.target.value)}
                  placeholder="Enter Your Email"
                  className="todo-row-margin input-addTodo"
                  required
                />
              </div>
              <div className="row">
                <textarea
                  type="text"
                  id="textarea"
                  name="textarea"
                  onChange={e=>setMessage(e.target.value)}
                  className="todo-row-margin input-addTodo"
                  required
                />
              </div>
              <div className="row justify-content-center">
                <button type="submit"> SEND </button>
              </div>
              <div className="row justify-content-center">
               {sent ?<label >You're message was sucessfully sent.</label> : null}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
export default Contact;
