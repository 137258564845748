import './Work.scss';



function Work() {
  return (
  <div className='container-work' id="work">
    <div className='wrapper-work'>
      <div className='main-work'>
        <label className='fw-bold'>WORK</label>
        <span className='dash-work'/>
      </div>
       
        <div className="timeline">
                <div className="timeline-wrapper left ">
                    <div className="content content-left-margin ">
                        <h3>Jan 2023 - Present</h3>
                        <p className='fw-bold'>Graduate Teaching Assistant</p>
                        <p ><span className='fw-bold'>University: </span>DePaul University, Chicago, IL</p>
                        <p><span className='fw-bold'>Description:</span> Received Full-time Graduate Assistantship Award. 
                          Working as a Tutor for the College of Computing and Digital Media Department.</p>
                    </div>
                </div>
                <div className="timeline-wrapper right ">
                    <div className="content content-right-margin">
                        <h3>Apr - Present</h3>
                        <p className='fw-bold'>Software Developer</p>
                        <p ><span className='fw-bold'>University: </span>DePaul University, Chicago, IL</p>
                        <p><span className='fw-bold'>Description:</span> Integrated a computer vision model to monitor COVID test process in real-time, ensuring consistent user and kit presence for chain
                              of custody verification achieving an accuracy rate of over 95% and reducing manual verification efforts by 30%.</p>
                  </div>
                </div>
                <div className="timeline-wrapper left">
                    <div className="content content-left-margin">
                        <h3>Jan 2020 - Mar 2022</h3>
                        <p className='fw-bold'>Software Developer and Onsit Coordinator</p>
                        <p ><span className='fw-bold'>Company: </span>Tata Consultancy Services, Japan</p>
                        <p><span className='fw-bold'>Description:</span> Led the development of a Computer Vision system with 95% accuracy, 
                        integrated OpenCV for template matching and EasyOCR for text detection, and created a high-performance C++ API for a 
                        Navigation Tracker DLL, resulting in a 30% reduction in information access time and 20% improved dashboard data precision at TCS(Nissan).</p>
                    </div>
                </div>
                <div className="timeline-wrapper right">
                    <div className="content content-right-margin">
                        <h3>Sept 2017 - Dec 2019</h3>
                        <p className='fw-bold'>Software Developer</p>
                        <p ><span className='fw-bold'>Company: </span>Tata Consultancy Services, India</p>
                        <p><span className='fw-bold'>Description:</span> Built a C# WPF-based Auto Test Data Generator using State Transition Matrix files for 98% pass rate in meter testing, 
                        and a Multimedia-CAN Simulator Application with XML database for 25% time reduction in ECU communication testing, plus 20% overall testing efficiency gain with Vector CANoe.</p>
                  </div>
                </div>
        </div>

      </div>
    </div>  
    
  );
}

export default Work;
